<div class="background-register">
    <header>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 mt-5 pt-5 text-center">
              <a href="/" class="no-decoration"><img class="logo" src="../../assets/img/logo.svg" width="200"></a>
          </div>
        </div>
  
      </div>
    </header>
  
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-10 mt-5">
  
          <ul class="list-group list-group-horizontal steps">
            <li class="col-12 col-sm-12 col-md-3 bb-1 text-center active"> Select account type</li>
            <div class="line"></div>
            <li class="col-12 col-sm-12 col-md-3 bb-1 text-center active">Enter email address</li>
            <div class="line"></div>
            <li class="col-12 col-sm-12 col-md-3 bb-1 text-center">Set up your account</li>
          </ul>
  
        </div>
      </div>



  <div class="container mt-6">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <input type="email" placeholder="Email address" formControlName="email" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              [(ngModel)]="personalDetails.email" #email />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback  pl-2">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
          <div class="form-group mb-6">
            <input type="email" placeholder="Confirm email address" formControlName="confirm_email" class="form-control form-control-lg"
              [class.is-invalid]="form.errors?.Match" [ngClass]="{ 'is-invalid': submitted && f.confirm_email.errors }" [(ngModel)]="personalDetails.confirm_email"
              #confirm_email />
            <small class="invalid-feedback" *ngIf="form.errors?.Match">
                Confirm email should match with the email address
              </small>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback pl-2">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
          <p class="verification mt-3 text-center"><small>Please check your email for verification.</small></p>

          <div class="row justify-content-center mb-md-0 mb-5">
            <div class="form-group col-12 mt-5 text-center">
              <button [disabled]="loading" class="btn btn-register mt-2 text-left">
                <span *ngIf="loading" class="float-left text-uppercase"></span>SUBMIT
                <img src="../../assets/img/arrow.png" class="arrow-login text-right mr-0" alt="" width="6">
              </button>
            </div>
          </div>
        </form>


      </div>

    </div>

  </div>
