import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import {Country} from '@app/_models';
import {Nationality} from '@app/_models';
import {Currency,PaymentMethod} from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AssetService {
  private countrySubject: BehaviorSubject<Country>;
  public countries: Observable<Country>;
  public currencies: Observable<Currency>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {

  }

  getCurrencies() {
    return this.http.get<Currency[]>(`${environment.apiUrl}/currencies/getAllActiveCurrencies`);
  }

  getPaymentMethods() {
    return this.http.get<PaymentMethod[]>(`${environment.apiUrl}/transactions/getAllPaymentMethods`);
  }

  getPrices() {
    return this.http.get<any[]>(`https://assets.assetise.io/api/prices`);
  }

  getCountries() {
    return this.http.get<Country[]>(`${environment.apiUrl}/countries/getAllActiveCountries`);
  }

  getNationalities() {
    return this.http.get<Nationality[]>(`${environment.apiUrl}/nationalities/getAllActiveNationalities`);
  }
  getDocumentStatuses() {
    return this.http.get<any>(`${environment.apiUrl}/documents/getDocumentStatuses`);
  }
  getDocumentTypes() {
    return this.http.get<any>(`${environment.apiUrl}/documents/getDocumentTypes`);
  }
  getDocumentTypesByClient(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/documents/GetDocumentTypesByClient/${id}`);
  }
  getDocumentTypesByCompany(id) {
    return this.http.get(`${environment.apiUrl}/documents/GetDocumentTypesByCompany/${id}`);
  }
  getRepresentativesByCompany(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/documents/GetRepresentativesByCompany/${id}`);
  }

  getRepresentativesKycStatus(id: number){
    return this.http.get<any>(`${environment.apiUrl}/documents/GetRepresentativesKycStatus/${id}`);

  }

}
