import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { first } from 'rxjs/operators';

import { UserService, AlertService } from '@app/_services';



export function matchValidator(
  matchTo: string,
  reverse?: boolean
): ValidatorFn {
  return (control: AbstractControl):
    ValidationErrors | null => {
    if (control.parent && reverse) {
      const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
      if (c) {
        c.updateValueAndValidity();
      }
      return null;
    }
    return !!control.parent &&
    !!control.parent.value &&
    control.value ===
    (control.parent?.controls as any)[matchTo].value
      ? null
      : { matching: true };
  };
}


@Component({
  templateUrl: 'reset-password.component.html',
  styleUrls: ['forgot-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    form: UntypedFormGroup;
    loading = false;
    submitted = false;
    reseted = false;
    returnUrl: string;
     token: string;
  postdata = {};
    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService
    ) { }

    public showPassword: boolean;
    public showPasswordOnPress: boolean;
    name = 'Angular';



    ngOnInit() {
      this.token = this.route.snapshot.params['token'];
        this.form = this.formBuilder.group({
            password: ['', [Validators.required,matchValidator('confirm_password', true)]],
              confirm_password: ['', [Validators.required, matchValidator('password')]],
          email: ['', Validators.required],
          token: ['', Validators.required]

        });

     var hidtoken= this.token.split("&",);
      this.f.email.setValue(hidtoken[1]);
      this.f.token.setValue(hidtoken[0]);
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }


    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;

      this.postdata['email'] = this.f.email.value;
      this.postdata['token'] = this.f.token.value;
      this.postdata['password'] = this.f.password.value;
      this.postdata['confirm_password'] = this.f.confirm_password.value;


        this.userService.resetPassword(this.postdata)
            .pipe(first())
            .subscribe(
                data => {
                 // alert(data)
                  this.reseted=true;
                  setTimeout(() => {

                    this.router.navigate(['/user/login']);
                  }, 10000);  //10s
                    //this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}

