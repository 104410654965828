<div class="background-register">
  <header>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 mt-5 pt-5 text-center">
            <a href="/" class="no-decoration"><img class="logo" src="../../assets/img/logo.svg" width="200"></a>
        </div>
      </div>
    </div>
  </header>


  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 text-center">
        <div *ngIf="!token && !loggeid" class="text-center">
          <h2 class="text-center pt-5 mt-5 mb-3 text-white">An activation link<br class="d-none d-lg-block" /> has been sent to your email!</h2>
          <p class="text-white opacity-50 fw-light lh-120">Please also check your junk mail folder<br class="d-none d-lg-block" /> in the event that you have not received it.</p>
        </div>

        <!--    <div *ngIf="token && !loggeid">
            <img src="assets/img/tick.svg" style="width:80px;" class="mt-5 pt-5 mb-2" />
            <h1 class="text-center mt-3 mb-3 text-white">Welcome to Assetise!</h1>
            <h4 class="text-center mb-3 text-white fw-light">Your Account has been succesfully activated!</h4>
          </div>
        </div>-->
        <div #expiredpar id="expiredpar" >

        </div>


        <div class="form-group col-12 mt-3 text-center">
          <button onclick="window.location.href='/login';" [disabled]="loading" class="btn btn-register mt-2 text-left" style="max-width: 150px;">
            <span *ngIf="loading" class="float-left text-uppercase"></span>LOG IN
            <img src="../../assets/img/arrow.png" class="arrow-login text-right mr-0" alt="" width="6">
          </button>
        </div>
      </div>

    </div>
  </div>














