<div class="background-login">
  <header>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 mt-5 pt-5 text-center">
          <a href="/" class="no-decoration"><img class="logo" src="../../assets/img/logo.svg" width="200"></a>
        </div>
      </div>
    </div>
  </header>

  <div *ngIf=" this.user && this.user.enabled2fa" class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 text-center">
        <h3 class="text-white text-center mt-5">Two-factor Authentication Verification</h3>

        <div *ngIf="this.tfa.setupCode" class="mt-3">
          <div class="qr-block mb-4 d-inline-block">
            <img [src]="tfa.barcodeImageUrl" alt="" style="display:block;margin:auto" width="150">
            <!--<p>Secret Key - {{tfa.setupCode || tfa.tempSecret}}</p>-->
            <p class="text-black pt-2 fw-bolder lh-120 mb-0"><small>Scan QR or copy code<br>and add it manually</small></p>
          </div>
          <button tooltip="Copied!" class="btn btn-register mt-2 text-center d-block mx-auto" (click)="copyText(tfa.setupCode)">
            <span *ngIf="loading" class="float-left text-uppercase"></span>CLICK TO GET CODE
          </button>
        </div>

        <!--<div *ngIf="!tfa.setupCode">
                <span *ngIf="!!tfa.tempSecret">
                    <img [src]="tfa.barcodeImageUrl" alt="" class="mt-5 mb-4" >
                    or
                    <button (click)="copyText(tfa.tempSecret)" class="btn btn-submit">Click to copy</button>
                   <p>Secret Key - {{tfa.tempSecret}}</p>
                    <p class="text-danger" style="text-align:center;" *ngIf="errorMessage">{{errorMessage}}</p>
                </span>
          </div>-->

        <form [formGroup]="tfaform" class="form-group mt-5 mb-3" (ngSubmit)="confirm()">
          <input formControlName="authcode" type="text" class="form-control col-sm-6 col-md-8 offset-xl-2 mb-4 mx-auto" maxlength="6"
            placeholder="Enter the Auth Code" id="authcode" autocomplete="off" required>

          <button [disabled]="loading" class="btn btn-verify mt-2 text-left d-block mr-3">
            <span *ngIf="loading" class="float-left text-uppercase"></span>VERIFY
          </button>

          <button [disabled]="loading" class="btn btn-cancel mt-2 text-left d-block mx-auto">
            <span *ngIf="loading" class="float-left text-uppercase"></span>CANCEL
          </button>

        </form>
      </div>
    </div>
  </div>

  <div *ngIf="!tfa && !user" class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 text-center">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-group mb-3 mt-6 text-center">
            <input type="text" placeholder="Email address" formControlName="email" class="form-control form-control-lg mt-3" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
          </div>

          <div class="form-group mb-3">
            <input type="password" placeholder="Password" formControlName="password" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>

          <div class="form-group text-right OpenSans mb-4 text-green">
            <a class="text-green" href="/forgot"><small>Forgot password?</small></a>
          </div>

          <div class="row justify-content-center mb-md-0 mb-5">
            <div class="form-group col-12 mt-4 text-center">
              <button [disabled]="loading" class="btn btn-register mt-2 text-left">
                <span *ngIf="loading" class="float-left"></span>SIGN IN
                <img src="../../assets/img/arrow.png" class="arrow-login text-right mr-0 mt-0" alt="" width="6">
              </button>
              <h6 class="mt-5 text-light"><small><a href="#" class="login link-underline-primary" routerLink="/user/register-step1">Need to register an account?</a></small></h6>
            </div>
          </div>

        </form>
      </div>
    </div>

  </div>


  <div *ngIf="this.tfa" class="container">

  </div>

</div>

