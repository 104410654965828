import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';

import { UserService, AlertService, AssetService } from '@app/_services';
import { PasswordDetails } from '@app/_models/password-details';
import { FormDataService } from '@app/_services/form-data.service';
import { PasswordValidator } from './password.validator';
import { CookieService } from 'ngx-cookie-service';
import { PersonalDetails } from '@app/_models/personal-details';
import { HttpService } from '../_services/http.service';
import {environment} from "@environments/environment";

@Component({
  templateUrl: 'activation.component.html',
  styleUrls: ['register.component.css']
})
export class ActivationComponent  {
  token: string;
  loading = false;
  expired=false;
  loggeid= false;
  returnUrl: string;
 // @ViewChild('expiredpar', {static: false}) expiredpar: ElementRef;
  @ViewChild('nonexpiredpar', {static: false}) nonexpiredpar: ElementRef;
  @ViewChild('expiredpar') expiredpar: ElementRef<HTMLElement>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private assetService: AssetService,
    private alertService: AlertService,
    private formDataService: FormDataService,
    private cookieService: CookieService,
    public http: HttpService
  ) {


  }

  ngAfterViewInit(): void {

    this.token = this.route.snapshot.params['token'];
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    console.log('isLoggedIn');
    console.log(this.userService.isLoggedIn );
    this.loggeid = this.userService.isLoggedIn;
    if ( !this.loggeid && this.token) {
//activationToken
        console.log('token');
        console.log(this.token );

      this.userService.activate(this.token )
        .pipe(first())
        .subscribe(
          data => {
            console.log('activate');
            console.log(data);

            let user = {
              firtsname: data['firstName'],
              lastname: data['lastName'],
              email: data['email']

            };
            //console.log('user');
            // console.log(user);
            this.http.sendEmail(`${environment.mailerUrl}/register`, user).subscribe(
              data => {
                let res:any = data;
                this.expiredpar.nativeElement.innerHTML ='   <h1 class="text-white mt-6">Welcome to Assetise!</h1>\n' +
                  '                  <img src="assets/img/tick.svg" style="width:64px;" class="mt-5 mb-4" />\n' +
                  '                  <h2 class="text-center mt-5 mb-5 text-white">Your Account has been succesfully activated!</h2>\n'
                console.log(
                  `${user.email} is successfully registered and mail has been sent `
                );
              },
              err => {
                console.log(err);
                this.loading = false;
              },() => {
                this.loading = false;
              }

            );
           // this.router.navigate([this.returnUrl]);

          },
          error => {
            this.alertService.error(error);
            this.expiredpar.nativeElement.innerHTML='    <h1 class="text-white mt-6">Your token has been expired !</h1>\n' +
              '\n' +
              '                <a href="mailto:chat@assetise.io" class="btn btn-submit btn-full text-uppercase br-0 mt-3"> Contact Support  <img src="../../assets/img/arrow.png" class="arrow-login text-right" alt="" width="8" height="12"> </a>';

            this.expired=true;
            this.loading = false;
          });
    }else{

      if(this.loggeid){
        this.alertService.error("You are already logged in.Please logged out and try again. ");
      }
      console.log('No token');
    }

  }
}
