<div class="background-register">
  <header>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 mt-5 pt-5 text-center">
            <a href="/" class="no-decoration"><img class="logo" src="../../assets/img/logo.svg" width="200"></a>
        </div>
      </div>
    </div>
  </header>

  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-10 mt-5">
        <ul class="list-group list-group-horizontal steps">
          <li class="col-12 col-sm-12 col-md-3 bb-1 text-center active"> Select account type</li>
          <div class="line"></div>
          <li class="col-12 col-sm-12 col-md-3 bb-1 text-center active">Enter email address</li>
          <div class="line"></div>
          <li class="col-12 col-sm-12 col-md-3 bb-1 text-center active">Set up your account</li>
        </ul>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row justify-content-center mt-6">
        <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-5">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-group">
              <input type="text" id="firstname" placeholder="First name" formControlName="firstname" class="form-control form-control-lg"
                [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" [(ngModel)]="personalDetails.firstname" />
              <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback pl-3"><small class="text-danger" *ngIf="f.firstname.errors.required">First name is required</small></div>
            </div>

            <div class="form-group">
              <input type="lastname" id="text" placeholder="Last name" formControlName="lastname" class="form-control form-control-lg"
                [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" [(ngModel)]="personalDetails.lastname" #lastname
              />
              <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback pl-3"><small class="text-danger" *ngIf="f.lastname.errors.required">Lastname is required</small></div>
            </div>

            <div class="form-group">
              <input type="password" id="password" placeholder="Enter password" formControlName="password" class="form-control form-control-lg"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" [(ngModel)]="passwordDetails.password" #password
              />

              <div *ngIf="submitted && f.password.errors" class="invalid-feedback pl-3">
                <small class="text-danger" *ngIf="f.password.errors.required">Password is required</small>
                <small class="text-danger" *ngIf="!f.password.valid"> Password must contain more than 8 characters, 1 upper case letter, and 1 special character</small>
              </div>

              <div class="text-danger" *ngIf="f.password.errors?.pattern">
                Password must contain more than 8 characters, 1 upper case letter, and 1 special character
              </div>
            </div>

            <div class="form-group">
              <input type="password" id="confirm_password" placeholder="Verify Password" formControlName="confirm_password" class="form-control form-control-lg"
                [class.is-invalid]="form.errors?.misMatch" [(ngModel)]="passwordDetails.confirm_password" #confirm_password
              />
              <small class="text-danger pl-3" *ngIf="form.errors?.misMatch"> Confirm password is required</small>
            </div>

            <div class="form-group mt-4 pt-2">
              <!-- Placeholder issue - we need to investigate why the attributes prevent the visibility of the placeholder-->
              <!--<select formControlName="currency" id="currency" class="form-control choose-currency" [ngClass]="{ 'is-invalid': submitted && f.currency.errors }" [(ngModel)]="passwordDetails.currency" #currency>
                <option disabled selected>Choose currency</option>
                <option value="USD">USD</option>
                <option value="GBP">GBP</option>
                <option value="EUR">EUR</option>
              </select>-->

              <select class="form-control choose-currency" id="currency" formControlName="currency" [ngClass]="{ 'is-invalid': submitted && f.currency.errors }">
                <!-- <option disabled selected hidden>Choose currency</option>-->
                 <option value=""  selected >Choose currency</option>

                 <option value="USD">USD</option>
                 <option value="GBP">GBP</option>
                 <option value="EUR">EUR</option>
               </select>
               <div *ngIf="submitted && f.currency.errors" class="invalid-feedback pl-3"><small class="text-danger" *ngIf="f.currency.errors.required">Currency is required</small></div>

             </div>

             <div class="form-group row px-3 mt-4">
               <select class="form-control col-3" id="phoneCode"  formControlName="phoneCode">
                   <option [ngValue]="0"  disabled selected hidden>Code</option>
                 <option [ngValue]="country.phoneCode" *ngFor="let country of countries">+{{country.phoneCode}}</option>

               </select>
               <input type="text" id="phone" placeholder="Phone" formControlName="phone" class="form-control form-control-lg col-9 pl-3" />
             </div>

             <div class="row justify-content-center mb-6 mt-4">
               <div class="form-group col-12 mt-3 text-center">
                 <button [disabled]="loading" class="btn btn-register mt-2 text-left">
                     <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>CREATE ACCOUNT
                     <img src="../../assets/img/arrow.png" class="arrow-login text-right mr-0" alt="" width="6">
                   </button>
               </div>
             </div>

             <input type="hidden" formControlName="email" class="form-control" />
             <input type="hidden" formControlName="username" class="form-control" />
             <input type="hidden" formControlName="client_type" class="form-control" />

           </form>
         </div>
       </div>
     </div>
   </div>


   <!-- Personal Details : {{this.formDataService.personalDetails | json}} <br />
 Address Details : {{this.formDataService.passwordDetails | json}} -->
