import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class ClientTypeConditionalGuard implements CanActivate {

  constructor(       private router: Router,
                     private userService: UserService) {

  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {
    const user = this.userService.userValue;
    console.log('canActivate');

    console.log(user);
    if (user.clientType.toUpperCase() == 'C') {

      return true;
    }

    //this.router.navigate(['/user/login'], { queryParams: { returnUrl: _state.url }});
    return false;
  }
}
