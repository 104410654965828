<link rel="stylesheet" type="text/css" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"/>

<div class="background-register">
  <header>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 mt-5 pt-5 text-center">
            <a href="/" class="no-decoration"><img class="logo" src="../../assets/img/logo.svg" width="200"></a>
        </div>
      </div>
    </div>
  </header>

    <div *ngIf="reseted" class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 text-center">
              <img src="assets/img/tick.svg" style="width:80px;" class="mt-5 pt-5 mb-3" />
              <h4 class="text-center mb-2 text-white">Your password has been<br>changed successfully.</h4>
          </div>

          <div class="form-group col-12 mt-4 text-center">
            <button onclick="window.location.href='/login';" [disabled]="loading" class="btn btn-register mt-2 text-left" style="max-width: 150px;">
              <span *ngIf="loading" class="float-left text-uppercase"></span>LOG IN
              <img src="../../assets/img/arrow.png" class="arrow-login text-right mr-0" alt="" width="6">
            </button>
          </div>
        </div>
      </div>

    <div class="container" *ngIf="!reseted">

      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 mt-4 text-center">
          <h3 class="sign-in text-center mt-5 text-white fs-4">Reset password?</h3>
          <p class="text-white opacity-50 fw-light lh-120 mt-3">Strong passwords include numbers, letters and punctuation marks.</p>

          <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-group mb-3 mt-5 inner-addon right-addon">
              <input [type]="showPassword ? 'text' : 'password'"
              class="form-control pwd-control" placeholder="Enter new password" formControlName="password" class="form-control form-control-lg mt-3" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
              <i alt="show" class="far fa-eye eye-show text-white" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
              <i alt="hide" class="far fa-eye-slash eye-hide text-white" (click)="showPassword = !showPassword" [class.hide]="!showPassword"></i>

              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
            </div>

            <div class="form-group mb-3 mt-4 inner-addon right-addon">
              <input [type]="showPassword ? 'text' : 'password'" placeholder="Confirm new password" formControlName="confirm_password" class="form-control form-control-lg mt-3" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <i alt="show" class="far fa-eye eye-show text-white" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
              <i alt="hide" class="far fa-eye-slash eye-hide text-white" (click)="showPassword = !showPassword" [class.hide]="!showPassword"></i>
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.confirm_password.errors.required">Password is required</div>
                <div *ngIf="f.confirm_password.hasError('matching')">Password need to match</div>
              </div>
            </div>

            <div class="row justify-content-center mb-md-0 mb-5">
                <div class="form-group col-12 mt-5 text-center">
                  <input type="hidden" formControlName="email" class="form-control" />
                  <input type="hidden" formControlName="token" class="form-control" />
                  <button [disabled]="loading" class="btn btn-register mt-2 text-left">
                    <span *ngIf="loading" class="float-left"></span>RESET PASSWORD
                    <img src="../../assets/img/arrow.png" class="arrow-login text-right mr-0" alt="" width="6">
                  </button>
                </div>
            </div>

          </form>

        </div>

      </div>

    </div>

</div>

