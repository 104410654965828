<div class="background-register">
    <header>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 mt-5 pt-5 text-center">
              <a href="/" class="no-decoration"><img class="logo" src="../../assets/img/logo.svg" width="200"></a>
          </div>
        </div>
      </div>
    </header>


    <div *ngIf="sendForgot" class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 text-center">
            <img src="assets/img/tick.svg" style="width:80px;" class="mt-5 pt-5 mb-2" />
            <h4 class="text-center mb-2 text-white">Your request was successful.</h4>
            <p class="lh-120"><small class="text-grey">Please check your emailto reset your password.<br>If you have not received the email please check your junk mail also.</small></p>
        </div>

        <div class="form-group col-12 mt-3 text-center">
          <button [disabled]="loading" class="btn btn-register mt-2 text-left" style="max-width: 150px;">
            <span *ngIf="loading" class="float-left text-uppercase"></span>LOG IN
            <img src="../../assets/img/arrow.png" class="arrow-login text-right mr-0" alt="" width="6">
          </button>
        </div>
      </div>
    </div>

    <div class="container" *ngIf="!sendForgot">

      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 mt-4 text-center">
          <h3 class="sign-in text-center mt-5 text-white fs-4">Forgot your password?</h3>

          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group mb-3 mt-3">
              <p class="text-white opacity-50 fw-light lh-120">Enter your email address below and we will<br>send you a password reset link.</p>
              <input type="text" placeholder="Email address" formControlName="email" class="form-control form-control-lg mt-5" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
              </div>
            </div>

            <div class="row justify-content-center mb-md-0 mb-5">
              <div class="form-group col-12 mt-5 text-center">
                <button [disabled]="loading" class="btn btn-register mt-2 text-left">
                  <span *ngIf="loading" class="float-left"></span>REQUEST
                  <img src="../../assets/img/arrow.png" class="arrow-login text-right mr-0" alt="" width="6">
                </button>
                <h6 class="mt-5 text-light"><small><a href="#" class="login link-underline-primary" routerLink="/user/login">Need to register an account?</a></small></h6>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>
