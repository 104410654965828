export const environment = {
  production: true,
  apiUrl: 'https://crm.assetise.io/api',
 // apiUrl:'http://localhost:80/api',
  mailerUrl: 'https://mailer.assetise.io/sendmail',
  kycUrl: 'https://identity.assetise.io/ondato',
  partnerUrl: 'https://assetise-affiliate.azurewebsites.net',
  IDVURL_Production: 'idv.ondato.com',
  IDVURL_Sandbox: 'https://sandbox-idv.ondato.com',
  TWILIO_ACCOUNT_SID:'',
  TWILIO_AUTH_TOKEN:'',
  kycApi_Key:"f28c5386-e2f7-42bb-b632-76abf3939d3e-087515a1-041c-4837-9b10-549dc3044831",
  kycApi_username:"crmapi",
  kycApi_password:"assetiseuser2022!!",
  recaptcha: {
    siteKey: '6Lco9YEmAAAAAMGakT1xNS_7yJZaqyUHOjg1dSac',
    serverKey:'6Lco9YEmAAAAABslOx_kX2P8yNDH34jdueh8RFNl'
  }
};
