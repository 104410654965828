
<div class="row">
  <div class="col-sm-12 col-lg-9 col-xl-9 offset-xl-1 offset-lg-1 pl-5">
  <h1 class="dashboard-heading mt-4 mb-4 text-white">Notifications History</h1>
  </div>
  </div>



<div class="row">
  <div class="col-sm-12 col-lg-9 col-xl-9 offset-xl-1 offset-lg-1 pl-5">



    <div class="row">
      <div class="col-sm-12">
      <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table">
        <thead class="thead-light">


        <tr>


          <th scope="col" sortable="notification">Notification</th>
          <th scope="col" sortable="cdate">Created Date</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let notification of notifications" class="rowSpecs">
          <td scope="row" class="align-middle" ><strong>{{ notification.notification }}</strong></td>

          <td class="align-middle text-grey">{{ notification.createdDate | date: 'dd-MM-yy h:mm:ss'}}</td>
        </tr>



        </tbody>
      </table>
    </div>
    </div>


  </div>


</div>
