<!-- The Popup -->
<div *ngIf="isPopupVisible" class="popup" (click)="onBackgroundClick($event)">
  <div class="popup-content">
    <span (click)="closePopup()" class="close">&times;</span>
    <p>In order to your account area to be secured please enable the Two Factor Authentication verification </p>
<span>{{ textfor2fa }} Two Factor Authentication</span>
    <label class="switch">
      <input type="checkbox" [checked]="isChecked" (change)="toggleSwitch()">
      <span class="slider round"></span>
    </label>

  </div>
</div>
