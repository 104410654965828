import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

import { environment } from '@environments/environment';
import {Account, User, Document, DocumentType, DocumentStatus} from '@app/_models';
import {FormGroup} from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class UserService {
    private userSubject: BehaviorSubject<any>;
    private userwalletSubject: BehaviorSubject<any>;
    public user: Observable<any>;
    public userwallet: Observable<any>;
  headerOptions: any = null

  _isLoggedIn: boolean = false

  authSub = new Subject<any>();
    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService

    ) {

      this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
      this.user = this.userSubject.asObservable();

      this.userwalletSubject = new BehaviorSubject<any>(JSON.parse( JSON.stringify(localStorage.getItem(('userwallet')))));
      this.userwallet = this.userwalletSubject.asObservable();
    }

    public get userValue(): any {

      return this.userSubject.value;
    }

  public get userwalletValue(): any {

    return this.userwalletSubject.value;
  }

  updatePassword(email){

    return  this.http.post(`${environment.apiUrl}/users/updatePassword`, {email})
      .pipe(map(x => {
        //alert(x);
        return x;
      }));

  }
  resetPassword(params){

    return  this.http.post(`${environment.apiUrl}/users/resetPassword`, params)
      .pipe(map(x => {
       // alert(x);
        return x;
      }));

  }

  loginAuth(email, password) {
    localStorage.removeItem('user');
    localStorage.removeItem('userwallet');
   /* if (userObj.authcode) {
      console.log('Appending headers');
      this.headerOptions = new HttpHeaders({
        'x-tfa': userObj.authcode
      });
    }*/
   //{ observe: 'response', headers: this.headerOptions }
    return this.http.post<any>(`${environment.apiUrl}/loginAuth`, { email, password }) .pipe(map(user => {

   //   if (user.client.enabled2fa &&  user.client.verified){
        var token_arr  ={accessToken:"",refreshToken:"",Expiration:""};

        console.log("refreshToken");
        console.log(user.refreshToken);

        console.log("TokenExpiration");

        token_arr.refreshToken=user.refreshToken;
        token_arr.Expiration=user.expiration;
        token_arr.accessToken=user.accessToken;
        localStorage.setItem('token', JSON.stringify(token_arr));
        localStorage.setItem('useremail', user.client.email);
      //} else{
          if (!user.client.enabled2fa && user!=false && user.client.verified) {
                      localStorage.setItem('user', JSON.stringify(user.client));
                      localStorage.setItem('client_wallet', JSON.stringify(user.wallet));
                        this.userwalletSubject.next(user.wallet);
                         this.userSubject.next(user);

        //  }

      }


      return user;
    }));

  }
  public get jwt_token(): any {
    var tokenarr=JSON.parse(localStorage.getItem('token'));
    console.log("tokenarr");
    console.log(tokenarr);
    var token=tokenarr.accessToken;
      return  token;
  }

  verifyAuth(email,token,Authcode) {
   var password="XcCSnIAdHwn3B";
    email=localStorage.getItem('useremail');
    console.log("token localStorage");
    console.log(localStorage.getItem('token'));
    var tokenarr=JSON.parse(localStorage.getItem('token'));
    console.log("tokenarr");
    console.log(tokenarr);
    token=tokenarr.refreshToken;
    var tokenExpiration=tokenarr.Expiration;
    return this.http.post<any>(`${environment.apiUrl}/verifyAuth`, {email,password,token,tokenExpiration,Authcode})   .pipe(map(user => {
      console.log("user verifyyy");
      console.log(user);
      if (user!=false && user.client.verified){

        localStorage.setItem('user', JSON.stringify(user.client));
        localStorage.setItem('client_wallet', JSON.stringify(user.wallet));
        this.userwalletSubject.next(user.wallet);
        this.userSubject.next(user);


      }


      return user;
    }));
  }

   sendkycAuthRepresenative(params) {

    return  this.http.post(`${environment.apiUrl}/documents/sendkycAuthRepresenative`, params)
      .pipe(map(x => {
       alert("Email Sent");
        return x;
      }));
  }

    async kycAuth(params) {
      console.log("kycAuth");
      var headerOptions = new HttpHeaders();
      let authorizationData = 'Basic ' + btoa(`${environment.kycApi_username}` + ':' + `${environment.kycApi_password}`);
      headerOptions = headerOptions.set('Content-Type', 'application/json').set('Access-Control-Allow-Origin','*').set('Authorization',authorizationData);
      console.log(params);

      let kyc_redirect_url = await this.http.post<any>(`${environment.kycUrl}/idv`,params,{ headers: headerOptions}).toPromise();
      return kyc_redirect_url;

    }





  login(email, password) {
        return this.http.post<any>(`${environment.apiUrl}/login`, { email, password })
            .pipe(map(user => {
              console.log('login');
              console.log(user);
              if (user.client.verified){

                localStorage.setItem('user', JSON.stringify(user.client));
                localStorage.setItem('client_wallet', JSON.stringify(user.wallet));
                this.userwalletSubject.next(user.wallet);
                this.userSubject.next(user);
              }


              return user;
            }));
    }

    get isLoggedIn(): boolean {
      let authToken = this.cookieService.get('access_token');
      return (authToken !== null && authToken!=="") ? true : false;
    }
    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        localStorage.removeItem('userwallet');
      localStorage.removeItem('token');
      localStorage.removeItem('useremail');
        //this.cookieService.delete('user');
        this.userSubject.next(null);
        let removeToken = this.cookieService.delete('access_token');
        this.cookieService.delete('client_wallet');
        this.cookieService.delete('user');
        if (removeToken == null){
            this.router.navigate(['/user/login']);


          }


    }


  public uploadAvatar(clientId,formData) {
    const body=JSON.parse(JSON.stringify(formData));
   var ImageFile= body.ImageFile;
    console.log("formData");
    console.log(formData);
    this.http.post(`${environment.apiUrl}/avatars/uploadAvatar/${clientId}`, formData, {reportProgress: true, observe: 'events'})
      .subscribe({
        next: (event) => {
         return (event);
        },
        error: (err: HttpErrorResponse) => console.log(err)
      });
   // return this.http.post<any>(`${environment.apiUrl}/avatars/uploadAvatar`, {formData});
    ///${body.clientId}
   /* return this.http.post<any[]>(`${environment.apiUrl}/avatars/UploadAvatar`, {ImageFile}, {
      reportProgress: true,
      observe: 'events'
    });*/
  }
    register(user: {}) {

      return this.http.post<User>(`${environment.apiUrl}/clients/register`, user);
    }

    activate(activationToken: string) {
      return this.http.post<User>(`${environment.apiUrl}/clients/activate/${activationToken}`,activationToken);
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/user`);
    }
  GetNotificationsByClientId(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/notifications/GetNotificationsByClient/${id}`);
  }
  GetCountNotificationsByClientd(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/notifications/GetCountNotificationsByClient/${id}`);
  }
  EnableDisable2faByClientd(id: string,isChecked:boolean) {

  return this.http.post<any>(`${environment.apiUrl}/clients/EnableDisable2faByClientd/${id}`,isChecked);

  }
  getById(id: string) {
        return this.http.get<any>(`${environment.apiUrl}/clients/GetClientDetailsById/${id}`);
    }
  getCompanyDetailsById(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/clients/GetCompanyDetailsById/${id}`);
  }

  async getUser(id: string) {
    let result = await  this.http.get<any>(`${environment.apiUrl}/clients/GetClientDetailsById/${id}`).toPromise();
    if(result){

      return result;
    }
  }


  getDocumentsByClientId(id: number){
    return this.http.get<any>(`${environment.apiUrl}/documents/getDocumentsByClientId/${id}`);

  }


  async getOndatoDocumentsByClientId(id: string){


    var headerOptions = new HttpHeaders();
    let authorizationData = 'Basic ' + btoa(`${environment.kycApi_username}` + ':' + `${environment.kycApi_password}`);
    headerOptions = headerOptions.set('Content-Type', 'application/json').set('Access-Control-Allow-Origin','*').set('Authorization',authorizationData);

    console.log("getOndatoDocumentsByClientId");
    let kyc_docs = await this.http.get<any>(`${environment.kycUrl}/getKycDetails/${id}`,{ headers: headerOptions}).toPromise();
    console.log(kyc_docs);
    return kyc_docs;

  }

  GetWalletsByClientId(id: number){
    return this.http.get<any>(`${environment.apiUrl}/clients/GetWalletsByClientId/${id}`);

  }
  GetEntitiesReportingByClientId(id: string){
    return this.http.get<any>(`${environment.apiUrl}/reporting/GetEntitiesReportingByClientId/${id}`);

  }
  GetTotalsByTypesByClientId(id: string){
    return this.http.get<any>(`${environment.apiUrl}/reporting/GetTotalsByTypesByClientId/${id}`);

  }
    /*postFile(clientId: number, pleFileToUpload: File = null, poaFileToUpload: File = null){

      const endpoint =`${environment.apiUrl}/clients/uploadDocumentsS3`;
      const formData: FormData = new FormData();
      formData.append('clientId', clientId.toString());

      if (pleFileToUpload != null){
        formData.append('pleFile', pleFileToUpload, pleFileToUpload.name);

      }
      if (poaFileToUpload != null){
        formData.append('poaFile', poaFileToUpload, poaFileToUpload.name);
      }

      return this.http
        .post(endpoint, formData);
    }*/

  documentInformation(documentFormGroup: FormData, adjustedExpiration: Date) {
    let result = new Document();


    result.name = documentFormGroup.get('name').toString();
    result.documentType = new DocumentType();
    result.documentType['id'] = parseInt(<string> documentFormGroup.get('documentTypeid'));
    result.documentType['name'] = documentFormGroup.get('documentType').toString();
    result.documentStatus = new DocumentStatus();
    result.documentStatus['id'] =2;
    result.documentStatus['name'] ='';
    result.expiration = adjustedExpiration;
    return result;
  }
  postFile(information: { formfileData: FormData; clientId: any; file: any; adjustedExpiration: Date }) {

    const endpoint =`${environment.apiUrl}/documents/uploadDocumentsS3`;
    const formData: FormData = new FormData();
    formData.append('clientId', information.clientId.toString());

    if (information != null) {
      let documentInformation = this.documentInformation(information.formfileData, information.adjustedExpiration);
      formData.append('documentInformation', JSON.stringify(documentInformation));

      formData.append('file', information.file, documentInformation.path);
    }
    console.log('formData');
    console.log(formData);
    return this.http
      .post(endpoint, formData, { observe: 'response' });
  }


    update(id, params) {
      //updateClient
      console.log('updateClient');
      console.log(params);
        return this.http.post(`${environment.apiUrl}/clients/updateClient`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {

                }
                return x;
            }));
    }
  UpdateUserPartner(partner){


    const headers = { 'content-type': 'application/json'}
    //const body=params;
   // const body=JSON.parse(JSON.stringify(params));

   // return this.http.post<any[]>(`${environment.apiUrl}/accounts/create`, body,{'headers':headers})

    return this.http.post(`${environment.apiUrl}/clients/UpdateUserPartner/${this.userValue.id}`,{ "partner":partner.toString()},{'headers':headers})
      .pipe(map(x => {
        // update stored user if the logged in user updated their own record
        console.log('UpdateUserPartner');
        console.log(x);
      }));

  }
  updateCompany(id, params, part) {
    //updateClient
    console.log('updateClient');
    console.log(params);
    return this.http.post(`${environment.apiUrl}/clients/updateCompany/${part}`, params)
        .pipe(map(x => {
          // update stored user if the logged in user updated their own record
        if (id == this.userValue.id) {

        }
        return x;
        }));
  }



}
