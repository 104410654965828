import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rejected-message',
  templateUrl: './rejected-message.component.html',
  styleUrls: ['./rejected-message.component.css']
})
export class RejectedMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
