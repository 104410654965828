<div class="app-container" [ngClass]="{ 'bg-assetise-new': user }">
  <div class="wrapper">
    <nav id="sidebar" *ngIf="user" class="d-none d-sm-none d-md-none d-lg-block d-xl-block">
      <app-sidemenu></app-sidemenu>
    </nav>

    <div id="content" *ngIf="user">
      <nav class="navbar navbar-expand-lg navbar-dark bg-assetise-bar">
        <div class="container-fluid">
          <a class="navbar-brand" href="#"><img class="nav-brand" src="assets/img/logo.svg" style="width:200px; height:auto;" class="d-xl-none d-lg-none"></a>
          <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><i class="fas fa-align-justify"></i></button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav navbar-nav ml-auto" style="align-items: center;">

              <li class="mr-3">
                <div class="dropdown"> <a class="btn btn-dropdown-menu dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                  <!--<label class="hoverable " for="fileInput">
                    <img [src]="url ? url : 'https://www.w3schools.com/howto/img_avatar.png'" class="circle-img" style="width:32px; height:32px;  border:2px solid #06B880; border-radius:16px;">
                    <div class="hover-text">Choose file</div>
                    <div class="background"></div>
                  </label>-->
                  <label class="m-0 pr-1">Partner</label>

                  <input id="fileInput" type='file' (change)="onSelectFile($event)"> </a>

                  <div class="dropdown-menu" style="background-color:#152835; color: #FFF; min-width:150px;border:0;margin-top: 10px;right: 0;left: auto;">

                    <a *ngIf="!user.refId" routerLink="/partner"  class="dropdown-item py-2 mt-2">Become a Partner</a>
                    <a *ngIf="user.refId" routerLink="/partner"  class="dropdown-item py-2 mt-2"> Partner Panel</a>

                    <a routerLink="/partner/partner-promos"  class="dropdown-item py-2">Promo Material</a>

                    <!--  <a class="dropdown-item py-2">Campaigns</a>-->
                   </div>
                 </div>
               </li>

              <!-- <li  class="nav-item nav-link text-white">

                 <div class="dropdown"> <a class="btn btn-dropdown-menu dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">

                   <label class="m-0 pr-1">Partner</label></a>



                   <div class="dropdown-menu" style="background-color:#152835; color: #FFF; min-width:150px;border:0;margin-top: 10px;right: 0;left: auto;">

                     <a  routerLink="/partner" routerLinkActive="active" class="dropdown-item py-2 mt-2">Become a Partner</a>

                     <a  routerLink="/partner/partner-promos"  class="dropdown-item py-2">Promo Material</a>

                     <a class="dropdown-item py-2">Campaigns</a>
                     <a class="dropdown-item py-2">Payouts</a>
                   </div> </div>
               </li>-->
              <li>
                <div class="dropdown"> <a class="btn btn-dropdown-menu dropdown-toggle user-menu px-1" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                  <img src="assets/img/icon-settings.svg" height="18">

               <!--   <input id="fileInput" type='file' (change)="onSelectFile($event)">-->
                </a>

                  <div class="dropdown-menu" style="background-color:#152835; color: #FFF; min-width:240px;border:0;margin-top: 10px;right: 0;left: auto;">
                    <span class="dropdown-item-text text-white">{{user.email}}</span>
                    <span class="dropdown-item-text text-white pr-0 mt-2">
                    <span class="d-inline-block drop-down-menu-user">Regular User</span>
                    <span class="d-inline-block float-right px-2 py-1 drop-down-menu-verified" style="font-size:9px;"> <img src="assets/img/icon-verified.svg" width="11" class="mr-1" /> Verified</span></span>
                    <a class="dropdown-item py-2 mt-2" routerLink="/home/entities" routerLinkActive="active"><img src="assets/img/icon-01.svg" width="10" class="mr-2 drop-down-menu-icon" /> Dashboard</a>

                    <a *ngIf="!user.enabled2fa" class="dropdown-item py-2 mt-2" (click)="openPopup();" ><img src="assets/img/group38.svg" width="10" class="mr-2 drop-down-menu-icon" />Two Factor Authentication</a>
                    <a *ngIf="user.enabled2fa" class="dropdown-item py-2 mt-2" (click)="openPopup();" ><img src="assets/img/icon-04.svg" width="10" class="mr-2 drop-down-menu-icon" /> Two Factor Authentication</a>

                    <a *ngIf="user.clientType=='I'" class="dropdown-item py-2" routerLink="/user/profile" routerLinkActive="active"><img src="assets/img/icon-02.svg" class="mr-2 drop-down-menu-icon" /> Profile</a>

                    <a *ngIf="user.clientType=='C'" class="dropdown-item py-2" routerLink="/user/business-profile" routerLinkActive="active"><img src="assets/img/icon-02.svg" width="10" class="mr-2 drop-down-menu-icon" /> Business Profile</a>

                   <!-- <a class="dropdown-item py-2" href="#" *ngIf="user.clientType=='I'" routerLink="/user/documents-list" routerLinkActive="active"><img src="assets/img/icon-03.svg" width="10" class="mr-2 drop-down-menu-icon" /> KYC Documents</a>

                    <a class="dropdown-item py-2" href="#" *ngIf="user.clientType=='C'" routerLink="/user/documentsBusiness-list" routerLinkActive="active"><img src="assets/img/icon-03.svg" class="mr-2 drop-down-menu-icon" /> KYC Documents</a>-->

                    <a class="dropdown-item py-2" target="_blank" href="https://assetise-marketing-dev.azurewebsites.net/help-center"> Help Center</a>

                    <span class="d-block mt-2 mb-2 mx-3" style="border-top:2px solid #1E443B;"></span>

                    <a class="dropdown-item py-2" (click)="logout()" href="#" style="cursor:pointer;"><img src="assets/img/icon-exit.svg" width="10" class="mr-2 drop-down-menu-icon" />Log out</a>
                  </div>
                </div>
              </li>

              <li class="nav-item nav-link text-white notifications"><a routerLink="/notifications" class="no-decoration"><img src="assets/img/icon-bell.svg" height="18"/><span id="unread_notificationsspan">{{unread_notifications}}</span></a></li>
              <li><hr class="vLine-green" style="border-right: 1px solid #06B880;height: 20px;"></li>
              <li class="nav-item nav-link text-white" #main_currency></li>
              <li><hr class="vLine-green" style="border-right: 1px solid #06B880;height: 20px;"></li>
             <!-- NOT NOW<li class="nav-item nav-link text-white"><img src="assets/img/icon-mode.svg" height="18"></li>-->

            </ul>
          </div>
        </div>
      </nav>

      <div *ngIf="user.needsKycRenew" class="row alert-success">
        <span>Please be advised that your KYC documents will expire soon.Proceed to <a href="/user/documents-list">Renew your KYC</a></span>
      </div>


      <app-popup
        [isPopupVisible]="isPopupVisible"
        (isPopupVisibleChange)="onPopupVisibilityChange($event)">
      </app-popup>

      <router-outlet></router-outlet>
      <alert></alert>


    </div>

    <div id="content_logged_out" *ngIf="!user">
      <router-outlet></router-outlet>
      <!--<button (click)="modalService.open('modal-1')">Open Modal 1</button>
      <jw-modal id="modal-1">
        <h1>A custom modal popup</h1>
        <p>Home page text: <input type="text" [(ngModel)]="bodyText" /></p>

        <button (click)="modalService.close();">Close</button>
      </jw-modal>-->

      <alert></alert>
    </div>

  </div>


</div>
