import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {AlertService, IpService, UserService} from '@app/_services';
import {User} from '@app/_models/user';
import {FormDataService} from '@app/_services/form-data.service';
import {EmailValidator} from './email.validator';
import {CookieService} from 'ngx-cookie-service';
import {Md5} from 'ts-md5';
import { LogService } from '@app/_services';

@Component({
  templateUrl: 'register-step2.component.html',
  styleUrls: ['register-step2.component.css']
})
export class RegisterStep2Component implements OnInit {
  private cookieValue: string;
  personalDetails: User;
  register_token: string;
  form: UntypedFormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private ip: IpService,
    private formDataService: FormDataService,
    private logger: LogService,
    private cookieService: CookieService
  ) {

    this.personalDetails = formDataService.personalDetails;

   }

  ngOnInit(): void {


    if(this.userService.userValue){
      this.router.navigate(['/home']);
      return;
    }
    const  hashregister = String(this.cookieService.get('ipAddress')) + String(this.cookieService.get('start_date'));
    const register_token2 = Md5.hashStr(hashregister) ;

    if (!this.cookieService.get('register_token') || register_token2 != String(this.cookieService.get('register_token')))
    {

      this.router.navigate(['../register-step1'], { relativeTo: this.route });
    }
    this.form = this.formBuilder.group({
      register_token: [],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      // email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.minLength(6)]],
      confirm_email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.minLength(6)]]
    }, {validator: EmailValidator});
    console.log("client_type");
   console.log( this.cookieService.get('client_type'));
      this.f.register_token.setValue(this.cookieService.get('register_token'));

  }

  // convenience getter for easy access to form fields
  // get email(){return this.form.get('email')}

  get f() { return this.form.controls; }



  onSubmit() {


    /*const {register_token: register_token1} = this.f;
    if(!(register_token1 == stringify(this.cookieService.get('register_token')))){

      return;
    }*/

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid ) {
      return;

    }



      // this.cookieService.set('email', this.personalDetails.email);
      // this.cookieValue = this.cookieService.get('email');

    this.cookieService.set('email', this.personalDetails.email);
    this.cookieValue = this.cookieService.get('email');

    this.router.navigate(['../register'], { relativeTo: this.route });
  }
}
