<div class="background-register">
  <header>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 mt-5 pt-5 text-center">
            <a href="/" class="no-decoration"><img class="logo" src="../../assets/img/logo.svg" width="200"></a>
        </div>
      </div>

    </div>
  </header>

  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-10 mt-5">

        <ul class="list-group list-group-horizontal steps">
          <li class="col-12 col-sm-12 col-md-3 bb-1 text-center active"> Select account type</li>
          <div class="line"></div>
          <li class="col-12 col-sm-12 col-md-3 bb-1 text-center">Enter email address</li>
          <div class="line"></div>
          <li class="col-12 col-sm-12 col-md-3 bb-1 text-center">Set up your account</li>
        </ul>

        <h2 class="sign-in mt-5 mb-5 text-center">Choose the type of account <br class="d-none d-lg-block" /> which best suits your needs.</h2>

      </div>
    </div>

    <div class="mx-4">
      <div class="row justify-content-center">

        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="col-12 col-md-8 mx-6">
          <div *ngIf="whitelist_allowed" class="row justify-content-center">
            <div class="col-xl-5 col-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <div class="form-group">
                <div class="form-check">
                  <mat-slide-toggle formControlName="isTesting">isTesting</mat-slide-toggle>

                  <label class="form-check-label ml-2" for="isTesting">isTesting</label>
                </div>
              </div>
            </div>
          </div>
          <ul class="row justify-content-center p-0">
            <li class="col-12 col-sm-6 col-md-5 col-lg-5 text-center">
              <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" id="radioAccount1" type="radio" formControlName="client_type" value="I" name="client_type" required>
                  <label class="form-check-label" for="radioAccount1">Personal account <div class="check"><div class="inside"></div></div></label>
                </div>
              </div>
            </li>

            <li class="col-12 col-sm-6 col-md-5 col-lg-5 text-center">
              <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="client_type" name="client_type" value="C" id="radioAccount2" required>
                  <label class="form-check-label" for="radioAccount2">Business account <div class="check"><div class="inside"></div></div></label>

                </div>
              </div>

            </li>
          </ul>
          <div *ngIf="submitted && f.client_type.errors" class="row justify-content-center mb-md-0 mb-5">
            <div class="form-group col-12 mt-3 text-center">
             <small class="text-danger" *ngIf="f.client_type.errors.required">Please choose type</small>

            </div>
          </div>
          <div class="row justify-content-center mb-md-0 mb-5">
            <div class="form-group col-12 mt-3 text-center">
              <button [disabled]="loading" class="btn btn-register mt-2 text-left">
                <span *ngIf="loading" class="float-left text-uppercase"></span>GET STARTED
                <img src="../../assets/img/arrow.png" class="arrow-login text-right mr-0" alt="" width="6">
              </button>
              <h6 class="mt-5 text-light"><small><a href="#" class="login link-underline-primary" routerLink="/user/login">Already have an account? Login</a></small></h6>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
