export class Account {
    id: number;
    client_id: number;
    name: string;
  accountTypeId: number;
    account_name: string;
    fundable: boolean;
     isWallet: boolean;
    withdrawable: boolean;
    allow_external: boolean;
    currency: string;
    balance: number;
    status: string;
    date_created: string;
    date_updated: string;
    token: string;
}
