import { AbstractControl } from '@angular/forms'; 

export function EmailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const email = control.get('email');
    const confirm_email = control.get('confirm_email');
    if (email.pristine || confirm_email.pristine) {
        return null;
    }
    return email && confirm_email && email.value !== confirm_email.value ?
        { Match: true} :
        null;
}
