<div class="row no-gutters">
    <div class="col-sm-12 col-lg-9 col-xl-9 offset-xl-1 offset-lg-1 pl-5">  
      <p class="text-xl-right" style="position:relative; top:60px;"><span style="color:#ACB9B2;">Residential country/region:</span> <span class="country-bar"><img src="/assets/img/cyprus-flag-icon-32.png" class="flag mr-2" /> Cyprus (Κύπρος)</span></p>
      <h1 class="dashboard-heading mt-4 mb-5 text-white">KYC Documents</h1>      

    </div>

</div>

<div class="row no-gutters mt-5">
    <div class="col-sm-12 text-center">
        <img src="assets/img/icon-completed.svg" class="text-center mb-3" />
        <h4 class="text-white font-weight-bold mt-2 mb-5">Your KYC request status was successful.</h4>
        <button class="btn btn-register mt-3 text-left"><span  class="float-left text-uppercase"></span>DASHBOARD <img src="../../assets/img/arrow.png" class="arrow-login text-right" alt="" width="8" height="12"></button>
    </div>
</div>
  