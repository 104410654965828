import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import { map } from  'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {Document, User} from '@app/_models';
import {Router} from '@angular/router';
import {environment} from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private documentsSubject: BehaviorSubject<Document>;
  public documents: Observable<Document>;

  constructor(private router: Router, private httpClient: HttpClient) {
    this.documentsSubject = new BehaviorSubject<Document>(JSON.parse(localStorage.getItem('documents')));
    this.documents = this.documentsSubject.asObservable();

  }
  public get documentsValue(): Document {
    return this.documentsSubject.value;
  }

  SERVER_URL = 'https://file.io/';


  public upload(formData) {

    return this.httpClient.post<any>(this.SERVER_URL, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }


  getDocuments(id: string) {
    return this.httpClient.get<User>(`${environment.apiUrl}/clients/GetDocumentsByClientId/${id}`);
  }

}
