import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Account,AccountType } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;
    private userwalletSubject: BehaviorSubject<any>;
    public userwallet: Observable<any>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.accountSubject = new BehaviorSubject<Account>(JSON.parse(localStorage.getItem('account')));
        this.account = this.accountSubject.asObservable();

      this.userwalletSubject = new BehaviorSubject<any>(JSON.parse( JSON.stringify(localStorage.getItem(('userwallet')))));
      this.userwallet = this.userwalletSubject.asObservable();
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }

  get_token(id: string){

    return this.http.get<String>(`${environment.apiUrl}/transfer_options/${id}`);

  }

  createCryptoAccount(params) {



    const headers = { 'content-type': 'application/json'}
    //const body=params;
    const body=JSON.parse(JSON.stringify(params));

    return this.http.post<any[]>(`${environment.apiUrl}/accounts/createCryptoAccount`, body,{'headers':headers})

  }
  createCFDAccount(params) {


    const headers = { 'content-type': 'application/json'}
    //const body=params;
    const body=JSON.parse(JSON.stringify(params));

    return this.http.post<any[]>(`${environment.apiUrl}/accounts/createCFDAccount`, body,{'headers':headers})

  }
  createOptionsAccount(params) {



    const headers = { 'content-type': 'application/json'}
    //const body=params;
    const body=JSON.parse(JSON.stringify(params));

    return this.http.post<any[]>(`${environment.apiUrl}/accounts/createForexAccount`, body,{'headers':headers})

  }

  createAccount(params) {



    const headers = { 'content-type': 'application/json'}
    //const body=params;
    const body=JSON.parse(JSON.stringify(params));

    return this.http.post<any[]>(`${environment.apiUrl}/accounts/create`, body,{'headers':headers})

    }


  async deposit(params) {
    let result = await this.http.post<any[]>(`${environment.apiUrl}/transactions/deposit`, params).toPromise();
    if(result){

      return result;
    }
  }

  async withdraw(params) {

      let resultwith = await this.http.post<any[]>(`${environment.apiUrl}/transactions/withdraw`, params).toPromise();
      return resultwith;

  }

  transfer(params) {

    return this.http.post<any[]>(`${environment.apiUrl}/transfers/transfer`, params);

  }

  getForexAccountByClientId(id: string) {
    return this.http.get<any[]>(`${environment.apiUrl}/clients/GetForexAccountByClientId/${id}`);

    //return this.http.get<Account[]>(`${environment.apiUrl}/clients/getAccountsByClientId/1`);
  }
  getOptionsAccountSession(id: string){
    return this.http.get<any[]>(`${environment.apiUrl}/accounts/OptionsAccountSession/${id}`);


  }
  getFxAccountSession(id: string){
    return this.http.get<any[]>(`${environment.apiUrl}/accounts/FxAccountSession/${id}`);


  }
  getOptionsAccountBalances(id: string){
    return this.http.get<any[]>(`${environment.apiUrl}/accounts/OptionsAccountBalances/${id}`);


  }
  getFxAccountBalances(id: string){
    return this.http.get<any[]>(`${environment.apiUrl}/accounts/FxAccountBalances/${id}`);


  }
  getOptionsAccountByClientId(id: string){
    return this.http.get<any[]>(`${environment.apiUrl}/clients/GetOptionsAccountByClientId/${id}`);


  }
  getCfdsAccountByClientId(id: string){
    return this.http.get<any[]>(`${environment.apiUrl}/clients/GetOptionsAccountByClientId/${id}`);


  }
  getCryptoAccountByClientId(id: string) {
    return this.http.get<any[]>(`${environment.apiUrl}/clients/GetCryptoAccountByClientId/${id}`);

    //return this.http.get<Account[]>(`${environment.apiUrl}/clients/getAccountsByClientId/1`);
  }
  getAll(id: string) {
      return this.http.get<any[]>(`${environment.apiUrl}/clients/GetAccountsByClientId/${id}`);

      //return this.http.get<Account[]>(`${environment.apiUrl}/clients/getAccountsByClientId/1`);
    }

  getTransferAccounts(id: string) {
    return this.http.get<Account[]>(`${environment.apiUrl}/accounts/getAllowedTransferAccountsByClientId/${id}`);
    }

  getAccountTypes() {
    return this.http.get<AccountType[]>(`${environment.apiUrl}/accounts/getAllActiveAccountTypes`);
  }

  getById(id: string) {
        return this.http.get<any>(`${environment.apiUrl}/accounts/GetAccountDetailsById/${id}`);
    }



  archive(id: string) {
    return this.http.delete(`${environment.apiUrl}/accounts/${id}`)
      .pipe(map(x => {

        return x;
      }));
  }

    update(id, params) {
        return this.http.put(`${environment.apiUrl}/accounts/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.accountValue.id) {
                    // update local storage
                    const account = { ...this.accountValue, ...params };
                    localStorage.setItem('account', JSON.stringify(account));

                    // publish updated user to subscribers
                    this.accountSubject.next(account);
                }
                return x;
            }));
    }


}
