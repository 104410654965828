export class AccountType {
  id: number;
  minTransferAllowed:number;
  maxTransferAllowed:number;
  isTransferAllowed: boolean;
  name: string;
  active: boolean;
  isFTDrequired: boolean;
}


