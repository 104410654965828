import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers';
import { LoginComponent } from './user/login.component';
import { ForgotPasswordComponent } from './user/forgot-password.component';
import {RegisterComponent} from '@app/user/register.component';
import {RegisterStep1Component} from '@app/user/register-step1.component';
import {RegisterStep2Component} from '@app/user/register-step2.component';
import {ActivationComponent} from '@app/user/activation.component';
import {LandingComponent} from 'src/app/landing/landing.component';
import {ResetPasswordComponent} from "@app/user/reset-password.component";
import {ApprovedMessagesComponent} from 'src/app/approved-messages/approved-messages.component';
import {RejectedMessageComponent} from 'src/app/rejected-message/rejected-message.component';
import {NotificationsComponent} from '@app/notifications/notifications.component';


/*const userModule = () => import('./user/user.module').then(x => x.UserModule);
const accountsModule = () => import('./accounts/accounts.module').then(x => x.AccountsModule);
const transactionsModule = () => import('./transactions/transactions.module').then(x => x.TransactionsModule);
const homeModule = () => import('./home/home.module').then(x => x.HomeModule);*/


const routes: Routes = [
    //{path: 'user/login', component: LoginComponent},
     { path: 'login', component: LoginComponent },
     { path: 'landing', component: LandingComponent },
     { path: 'forgot', component: ForgotPasswordComponent },
     { path: 'register', component: RegisterComponent },
     { path: 'register-step1', component: RegisterStep1Component },
    { path: 'register-step1/:refid', component: RegisterStep1Component },
     { path: 'register-step2', component: RegisterStep2Component },
     { path: 'activation', component: ActivationComponent },
     { path: 'activation/:token', component: ActivationComponent },
       { path: 'successfulKyc', component: ApprovedMessagesComponent },
       { path: 'failureKyc', component: RejectedMessageComponent },
      { path: 'rejectedKyc', component: RejectedMessageComponent },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
     { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
     { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent , canActivate: [AuthGuard]},
     { path: 'accounts', loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard] },
     //{ path: 'transfers', loadChildren: () => import('./transfers/transfers.module').then(m => m.TransfersModule), canActivate: [AuthGuard] },
     //{ path: 'transfers/:transfer_token', loadChildren: () => import('./transfers/transfers.module').then(m => m.TransfersModule), canActivate: [AuthGuard] },
  { path: 'reporting', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule), canActivate: [AuthGuard] },

    { path: 'transactions', loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule), canActivate: [AuthGuard] },
  { path: 'partner', loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule), canActivate: [AuthGuard] },



 // { path: 'user', loadChildren: userModule },
 // { path: 'user', loadChildren: './user/user.module#UserModule' },

    // otherwise redirect to home
   { path: '**', component: LoginComponent }
 // { path: '**', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {enableTracing: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
