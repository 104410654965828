import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import {Currency} from "@app/_models";
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class IpService  {
   ipAddress ='0.0.0.0';
  constructor(private http: HttpClient) { }
   async  getIPAddress()
  {
    //https://jsonip.com/
    //http://api.ipify.org/?format=json
    let res = await  this.http.get<any>("https://jsonip.com/").toPromise();
    // .subscribe((res:any)=>{
    if(res){
      this.ipAddress = res.ip;
    }

    return this.ipAddress;
    // });

    // return ipAddress;
  }
  async  getIPCountry()
  { var country ='CY';
    //https://jsonip.com/
    //http://api.ipify.org/?format=json
    try {


      /*const request = await fetch(`https://ipinfo.io/${this.ipAddress }/geo?token=27e4c284402602`, {
        method: 'GET',
        "Access-Control-Allow-Origin": "",
        "Content-Type": "application/json",
        "charset": "utf-8"
      });*/

      let resi = await  this.http.get<any>("https://jsonip.com/").toPromise();
      // .subscribe((res:any)=>{
      if(resi){
        this.ipAddress = resi.ip;
      }
      var headerOptions = new HttpHeaders();
      let authorizationData = 'Bearer 27e4c284402602' ;
      headerOptions = headerOptions.set('Content-Type', 'application/json').set('Access-Control-Allow-Origin','*');
        //.set('Authorization',authorizationData);


      let res = await  this.http.get<any>(`https://ipinfo.io/${this.ipAddress }/geo?token=27e4c284402602`,{ headers: headerOptions}).toPromise();
      // .subscribe((res:any)=>{
      console.log('res ', res);
      if(res){
        country = res.country;
      }

    } catch (e) {
      console.error(e);
    }

    return country;

    // });

    // return ipAddress;
  }

  getWhitelistIps() {
    return this.http.get<any[]>(`${environment.apiUrl}/settings/GetWhitelistIps`);
  }

  private handleError(error: HttpErrorResponse):
    Observable<any> {
    //Log error in the browser console
    console.error('observable error: ', error);

    return Observable.throw(error);
  }
}
