import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

import { UserService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService,
        private cookieService: CookieService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.userService.userValue;
        console.log('canActivate');

      console.log(user);

      if (user) {
           // authorised so return
           return true;
       }
       // not logged in so redirect to login page with the return url
       this.router.navigate(['/user/login'], { queryParams: { returnUrl: state.url }});
       return false;
    }
}
