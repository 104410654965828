import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { UserService, AlertService } from '@app/_services';
import { Clipboard } from '@angular/cdk/clipboard';
import {CookieService} from "ngx-cookie-service";
import 'rxjs/add/operator/filter';
@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})


export class LoginComponent implements OnInit {
    form: UntypedFormGroup;
    tfaform: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    tfa : any;
  authcode: string = "";
  errorMessage: string = null;
  tfaFlag: boolean = false
  user: any;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private cookieService: CookieService,
        private alertService: AlertService,
        private clipboard: Clipboard
    ) { }

    ngOnInit() {
      this.user = this.userService.userValue;
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
      this.tfaform = this.formBuilder.group({
        authcode: ['', Validators.required]

      });
      console.log('params');
      console.log(this.route.snapshot.params);
      this.route.queryParams.filter(params => params.url)
        .subscribe(params => {
          console.log("params");
          console.log(params); // { category: "fiction" }
           // this.category = params.category;
         //   console.log(this.category); // fiction
            this.cookieService.set('url',  params.url);

          }
        );


      /*if(this.route.snapshot.params['url']){

        this.cookieService.set('url',  this.route.snapshot.params['url']);
      }else{

        this.cookieService.set('url', '');
      }*/
      console.log('url');
      console.log( this.cookieService.get('url'));
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        if(this.user){

          this.kyc_auth(this.user);
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

  get tpa_f() { return this.tfaform.controls; }

  kyc_auth(data){


      if (data.clientStatus.name.toUpperCase() == 'DECLINED') {

        this.alertService.error('Your Account is not valid', { keepAfterRouteChange: true });
        this.loading = false;
        return;
      }else if (data.clientKYCStatus.name.toUpperCase() == 'PENDING') {

        if (data.clientType.toUpperCase() == 'C') {

          if (data.clientStatus.name.toUpperCase() == 'WAITING-APPROVAL') {
            window.location.href = '/user/documentsBusiness-list';
          }else{
            window.location.href = '/user/business-profile';
          }

        }else{
          window.location.href = '/user/documents';
        }

      /*  var postdata = {};
        postdata['email'] = data.email;
        postdata['firstName'] = data.firstName;
        postdata['lastName'] = data.lastName;
        postdata['middleName'] = data.lastName;
        postdata['personalCode'] = data.postcode;
        postdata['phoneNumber'] = data.phone;

        postdata['dateOfBirth'] = data.birthdate;
        postdata['externalReferenceId']=data.id;


        this.userService.kycAuth(postdata).then((responseItem) => {
          console.log("kycAuth");
          console.log("data");
          console.log(data);
          if(responseItem.response){
            window.location.href = `${environment.IDVURL_Sandbox}/?id=`+responseItem.responseid;

          }
          // window.location.href = data;
        }).catch(
          err => console.log(err)
        );*/
      } else if (data.clientKYCStatus.name.toUpperCase() == 'APPROVED' || data.clientKYCStatus.name.toUpperCase() == 'WEBTRADER' ) {
        console.log('url approved');
        console.log( this.cookieService.get('url'));
        if(this.cookieService.get('url')!=""){

          window.location.href ='/'+this.cookieService.get('url');
        }else{
          window.location.href = '/home';
        }

      }



  }

  redirect_user(client){
      console.log("redirect_user");  console.log(client);
    if (client.clientStatus.name.toUpperCase() == 'UNCOMPLETE') {
      if (client.clientType == 'C') {
        window.location.href = '/user/business-profile';
      } else {
        window.location.href = '/user/profile';
      }

    } else {
      if (client.clientKYCStatus.name.toUpperCase() == 'PENDING') {
        //  this.kyc_auth(data);

        if (client.clientType == 'C') {
          window.location.href = '/user/business-profile';
        } else {
          window.location.href = '/user/documents';
        }

      } else {
        //window.location.href = '/home';
        if(this.cookieService.get('url')!=""){

          window.location.href ='/'+this.cookieService.get('url');
        }else{
          window.location.href = '/home';
        }
      }
    }

  }
  confirm() {
    console.log("confirm");
    console.log(this.tpa_f.authcode.value);
    console.log("user");
    console.log(this.user);
    if(this.user){
      this.alertService.clear();
      if( this.tpa_f.authcode.value==""){
        this.alertService.error('Invalid verify code', {keepAfterRouteChange: true});
        return ;
      }else {




        this.userService.verifyAuth(this.user.email, this.user.token, this.tpa_f.authcode.value).subscribe((data) => {
          const result = data;
          console.log(data);

          console.log(result);
          // this.errorMessage = null;
          // this.tfa.secret = this.tfa.tempSecret;
          // this.tfa.tempSecret = "";
          if (result) {
            this.user = data;

            //this.kyc_auth(data);


            console.log("status");
            console.log(data.client.clientStatus);
            console.log("status clientKYCStatus");
            console.log(data.client.clientKYCStatus);
            this.redirect_user(data.client);

          } else {
            this.alertService.clear();

            this.alertService.error('Invalid verify code', {keepAfterRouteChange: true});
          }


        });
      }

    }

  }
  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }
    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;

      // this.userService.login(this.f.email.value, this.f.password.value)
      this.userService.loginAuth(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    //this.router.navigate([this.returnUrl]);


                 /* if (data.body['status'] === 200) {
                    //this._loginService.updateAuthStatus(true);
                    window.location.href = '/user/twoauth';
                  }
                  if (data.body['status'] === 206) {
                    this.tfaFlag = true;
                  }*/
                  if (data.client.verified === false) {
                    this.alertService.error('Your Account is not verified', {keepAfterRouteChange: true});
                    this.loading = false;
                    return;
                  }else{

                    console.log("tfadata");
                    console.log(data);
                    this.loading = false;
                    this.tfa=data;
                    this.user=data.client;
                    if( this.user.enabled2fa===false){
                      this.redirect_user(data.client);

                    }


                  }


                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
