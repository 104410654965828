import { Component,OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {AccountService, TransactionService, UserService} from '@app/_services';
import {MatDatepickerModule} from '@angular/material/datepicker';
import * as $ from 'jquery';
import 'datatables.net';
import {User} from "@app/_models";
import { Subject} from 'rxjs';

import { HttpClient } from '@angular/common/http';

@Component({
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.css']
})
export class NotificationsComponent {
  date = new UntypedFormControl(new Date());
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  serializedDate = new UntypedFormControl((new Date()).toISOString());
  postdata = {};
  user: User;
  notifications: any;
  form: UntypedFormGroup;

    constructor(
                private userService: UserService,
                private http: HttpClient,
                private formBuilder: UntypedFormBuilder) {}

    ngOnInit() {
      this.dtOptions = {
        pagingType: 'full_numbers',
        order: [[0, 'desc']],
        pageLength: 10
      };
      this.user=  this.userService.userValue;

      this.userService.GetNotificationsByClientId(this.user.id)
        .subscribe(x => {
console.log("GetNotificationsByClientId");
          // this.sub.updateBalanceElem(x.balance, x.currencyId);
          this.notifications=x;
          console.log(x);
            console.log(    this.notifications);
        });

    }


  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }



}
