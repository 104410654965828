import {Deserializable} from './deserializable.model';

export class User implements Deserializable  {
  id: string;
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  email: string;
  confirm_email: string;
  lastLoginDate: string;
  phone: string;
  country: string;
  city: string;
  postcode: string;
  nationality: string;
  birthdate: string;
  accept_terms: boolean;
  NeedsKycRenew: boolean;
  reg_date: string;
  register_ip: string;
  main_currency: string;
  client_type: string;
  clientStatus: any;
  verified: boolean;
  clientKYCStatus: any;
  total_deposits: number;
  total_withd: number;
  total_transf: number;
  master_balance: number;
  createdDate: string;
  updatedDate: string;
  token: string;
  activationToken: string;
  accounts:any;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}


