<style>
    .fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
<div *ngFor="let alert of alerts; let i = index" class="{{cssClass(alert)}} position_{{(i+1)*100}} fade-in" style="position:fixed; right:10px; top:{{(i+1)*50}}px;">
    <a class="close" (click)="removeAlert(alert)" style="cursor:pointer;">&times;</a>
    <span [innerHTML]="alert.message" class="mr-2"></span>
</div>
