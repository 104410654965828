import { Component, Input, Output, EventEmitter } from '@angular/core';
import {UserService} from "@app/_services";

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent {
   textfor2fa="Enable";
  @Input() isPopupVisible: boolean = false;
  @Output() isPopupVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isChecked: boolean = false;
  @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private userService: UserService){
    var user = this.userService.userValue;
    if(user.enabled2fa){
      this.isChecked =true;
      this.textfor2fa="Disable";
    }else{
      this.isChecked =false;
    }

  }
  toggleSwitch(): void {
    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
    this.enable2fa(this.isChecked);
  }
  closePopup(): void {
    this.isPopupVisible = false;
    this.isPopupVisibleChange.emit(this.isPopupVisible);
  }
  enable2fa(isChecked){
    var user = this.userService.userValue;
    this.userService.EnableDisable2faByClientd(user.id,isChecked)
      .subscribe(x => {
        this.userService.logout();

      });
  }
  onBackgroundClick(event: MouseEvent): void {
    if (event.target === event.currentTarget) {
      this.closePopup();
    }
  }
}
