import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { UserService } from '@app/_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private userService: UserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.userService.userValue;

        console.log('user');
        console.log(user);


        const isLoggedIn = user && (localStorage.getItem('token'));
        const isApiUrl = request.url.startsWith(environment.apiUrl);
      console.log("********");

      console.log("isLoggedIn"); console.log(isLoggedIn);

      console.log("isApiUrl");  console.log(isApiUrl);
        if (isLoggedIn &&isApiUrl ) {
         // const token=localStorage.getItem('token');
          var tokenarr=JSON.parse(localStorage.getItem('token'));
          console.log("tokenarr");
          console.log(tokenarr);
          var token=tokenarr.accessToken;
          var tokenExpiration=tokenarr.Expiration;
          console.log("toekn");
          console.log(token);
          console.log("tokenExpiration");
          console.log(tokenExpiration);
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }


}
