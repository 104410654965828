import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import {UserService, AlertService, IpService} from '@app/_services';
import { CookieService } from 'ngx-cookie-service';
import {FormDataService} from '@app/_services/form-data.service';
import { LogService } from '@app/_services';

import {User} from '@app/_models/user';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  templateUrl: 'register-step1.component.html',
  styleUrls: ['register-step1.component.css']
})
export class RegisterStep1Component implements OnInit {

  cookieValue: string;
  ipAddress: string;
  personalDetails: User;
  register_token: string | Int32Array;
  form: UntypedFormGroup;
  loading = false;
  submitted = false;
  whitelist_allowed = false;
  currentdate: Date;
  formvalues: {};
  whitelistIps:any[];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private formDataService: FormDataService,
    private userService: UserService,
    private alertService: AlertService,
    private ipservice: IpService,
    private logger: LogService,
    private cookieService: CookieService
  ) {   this.personalDetails = formDataService.personalDetails; }

  ngOnInit() {
    this.currentdate = new Date();

    if(this.userService.userValue){
      this.router.navigate(['/home']);
      return;
    }
    this.whitelistIps=["69.6.18.66"];
    this.getIP();
      //new Date().toJSON("yyyy/MM/dd HH:mm");
    this.form = this.formBuilder.group({
      client_type: ['', Validators.required],
      startdate: [''],
      isTesting: false
    });

   /* this.ipservice.getWhitelistIps().subscribe((data: any[]) => {
      this.whitelistIps = data;
      console.log('whitelistIps');
      console.log(this.whitelistIps);
    });*/

    this.f.startdate.setValue(this.currentdate);
    console.log('refid111');
    console.log(this.route.snapshot.params['refid']);
    if(this.route.snapshot.params['refid']){

      this.cookieService.set('refid',  this.route.snapshot.params['refid']);
    }else{

      this.cookieService.set('refid', '');
    }
    this.cookieService.set('register_token', '');
    this.cookieService.set('start_date', '');
    this.cookieService.set('ipAddress', '');

  }

  get f() {  return this.form.controls; }

  getIP() {
   // this.ipAddress ='0.0.0.0';
   this.ipservice.getIPAddress().then((x) => {
if( this.whitelistIps.indexOf(x) > -1){
  this.whitelist_allowed=true;
}
     this.ipAddress =x;
     this.f.isTesting.setValue(true);
   });

  }

  onSubmit() {
    this.submitted = true;
    console.log("onSubmit");
console.log(this.f.client_type.errors);
    if (this.form.invalid ){

      return;
    }
    this.formvalues = this.form.value;

    const currentdate = Date.now(); //this.currentdate.toJSON("yyyy/mm/dd");

    const hashregister = String(this.ipAddress) + String(currentdate);

    this.register_token = Md5.hashStr(hashregister) ;

    this.cookieService.set('client_type',  this.formvalues['client_type']);
    this.cookieService.set('register_token',  String(this.register_token));
    this.cookieService.set('start_date', String(currentdate));
    this.cookieService.set('ipAddress', this.ipAddress);
    this.cookieService.set('isTesting', this.formvalues['isTesting']);

    console.log('refid');
    console.log( this.cookieService.get('refid'));
    console.log('***refid');
    //this.logger.log('register_token avriable 888=' + String(this.register_token));

    //this.logger.log('register_token==' + String(this.cookieService.get('register_token' )) );
    //this.logger.log('ipAddress==' + String(this.cookieService.get('ipAddress')));
    //this.logger.log('start_date===' + String(this.cookieService.get('start_date')));
    this.router.navigate(['/register-step2' ], { relativeTo: this.route });


  }
}
