import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { first } from 'rxjs/operators';

import { UserService, AlertService, AssetService , IpService,ModalService} from '@app/_services';
import { PasswordDetails } from '@app/_models/password-details';
import { FormDataService } from '@app/_services/form-data.service';
import { PasswordValidator } from './password.validator';
import { CookieService } from 'ngx-cookie-service';
import { PersonalDetails } from '@app/_models/personal-details';
import { HttpService } from '../_services/http.service';
import {environment} from "@environments/environment";
import { ReCaptchaV3Service } from 'ng-recaptcha';

import { User } from '@app/_models';
@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.css']
})
export class RegisterComponent implements OnInit {
    passwordDetails: PasswordDetails;
    personalDetails: PersonalDetails;

    private cookieValue: string;
  phone: FormControl;
  firstname: FormControl;
  lastname : FormControl;
  password : FormControl;
  confirm_password: FormControl;
  currency  : FormControl;
  phoneCode : FormControl;
  email  : FormControl;
  username  : FormControl;
  client_type  : FormControl;
  reCAPTCHAToken: string;
    form: FormGroup;
  countryphoneCode='';
  countries :any;
    postdata = {};
    postdata1 = {};
    loading = false;
    submitted = false;
  ipCountry: string;
    register_token: string;
     activationtoken: string;
     user: User;
  bodyText = 'This text can be updated in modal 1';

  constructor(
        private formBuilder: FormBuilder,
        private recaptchaV3Service: ReCaptchaV3Service,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private assetService: AssetService,
        private alertService: AlertService,
        private formDataService: FormDataService,
        private cookieService: CookieService,
        protected modalService: ModalService,
        private ip: IpService,
        public http: HttpService
    ) {
      this.passwordDetails = formDataService.passwordDetails;
      this.personalDetails = formDataService.personalDetails;

     }

    ngOnInit() {
      // function emailDomainValidator(control: FormControl) {
      //   let email = control.value;
      //   if (email && email.indexOf("@") != -1) {
      //     let [_, domain] = email.split("@");
      //     if (email !== "codecraft.tv") {
      //       return {
      //         emailDomain: {
      //           parsedDomain: domain
      //         }
      //       }
      //     }
      //   }
      //   return null;
      // }
      //this.user= JSON.parse( this.user);


      this.user= JSON.parse( this.userService.userValue);

      if(this.userService.userValue){
        this.router.navigate(['/home']);
        return;
      }

      this.getcountryIP();

     /* this.firstname = new FormControl('', Validators.required);
      this.lastname = new FormControl('', Validators.required);
      this.password = new FormControl('', Validators.required);
      this.confirm_password = new FormControl('', PasswordValidator);
      this.currency = new FormControl('', Validators.required);

      this.phoneCode = new FormControl('', Validators.required);
      this.phone = new FormControl('', Validators.required);
      this.email = new FormControl('');
      this.username = new FormControl('');
      this.client_type = new FormControl('');
      this.form =  new FormGroup({
        'firstname': this.firstname,
        'lastname' : this.lastname,
        'password' : this.password,
        'confirm_password': this.confirm_password,
        'currency':   this.currency,
        'phoneCode': this.phoneCode,
        'phone': this.phone,
        'email': this.email ,
        'username': this.username,
        'client_type':  this.client_type
      });*/
      this.form =    this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            password: ['', Validators.required  ,Validators.pattern(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
            )],
            confirm_password: ['', Validators.required],
            currency: ['', Validators.required],
             phoneCode: [''],
            phone: ['', Validators.required],
        email: [],
            username: [],
            client_type: []
        }, {validators: [PasswordValidator]});


      this.f.email.setValue(this.cookieService.get('email'));
      this.f.username.setValue(this.cookieService.get('email'));
      this.f.client_type.setValue(this.cookieService.get('client_type'));


    }


    getcountryIP() {
      this.ipCountry = '';
      this.ip.getIPCountry().then((x) => {
console.log("getIPCountry"); console.log(x);
        this.ipCountry =x;
        this.assetService.getCountries().subscribe((data: any[]) => {
          this.countries = data;
          console.log("countryphoneCode");
          console.log(this.ipCountry);
          for(let country of data){

            if(country.iso === this.ipCountry){
              console.log("country");
              console.log(country);
              this.countryphoneCode =country.phoneCode
            }
          }
          console.log(this.countryphoneCode);
          this.f.phoneCode.setValue(this.countryphoneCode);

        });

      });
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }



  private prepareSave(): User {
    return new User().deserialize(this.form.value);
  }

   onSubmit() {
     this.recaptchaV3Service.execute('importantAction')
       .subscribe((token: string) => {
         console.log(`Token [${token}] generated`);
         this.reCAPTCHAToken = token;
       });
     this.alertService.clear();

     this.submitted = true;
     // stop here if form is invalid
     if (this.form.invalid ){

       return;
     }else {


       //const postdata = this.prepareSave();
       /* this.cookieService.set('password', this.passwordDetails.password);
        this.cookieService.set('currency', this.passwordDetails.currency);
        this.cookieService.set('email', this.personalDetails.email);*/
       this.postdata1['email'] = this.f.email.value; //this.cookieService.get('email');
       this.postdata1['password'] = this.f.password.value; //this.cookieService.get('password');
       this.postdata1['ipCountry'] = this.ipCountry;
       this.postdata['email'] = this.f.email.value;//this.cookieService.get('email');
       this.postdata['firstname'] = this.f.firstname.value;
       this.postdata['lastname'] = this.f.lastname.value;
       this.postdata['mainCurrency'] = this.f.currency.value;
       this.postdata['clientType'] = this.cookieService.get('client_type');
       this.postdata['refid'] = this.cookieService.get('refid');
       this.postdata['phoneCode'] = this.f.phoneCode.value;
       this.postdata['phone'] = this.f.phone.value;
       this.postdata['countryId'] = '0';

       this.postdata['city'] = '';
       this.postdata['postcode'] = '';
       this.postdata['nationalityId'] = 0;
       this.postdata['birthdate'] = '01/01/1970';
       this.postdata['accept_terms'] = true;
       this.postdata['registerIp'] = this.cookieService.get('ipAddress');
       this.postdata['status'] = 'PENDING';
       this.postdata['kyc_status'] = 'PENDING';
       this.postdata['verified'] = 0;
       this.postdata['total_deposits'] = 0;
       this.postdata['total_withd'] = 0;
       this.postdata['total_transf'] = 0;
       this.postdata['master_balance'] = 0;
       this.postdata['activationToken'] = '';
       this.postdata['isTesting'] = this.cookieService.get('isTesting');
       this.postdata1['client'] = this.postdata;

       console.log("submit");

       console.log(this.postdata1);


       // reset alerts on submit


       this.loading = true;

          this.userService.register(this.postdata1)
                   .pipe(first())
                   .subscribe(
                       data => {
                         //console.log('activationtoken');
                        // console.log(data);
                         //this.activationtoken=data.activationToken;

                         this.alertService.success('Registration successful', { keepAfterRouteChange: true });

                         let user = {
                            firtsname: data['firstName'],
                            lastname: data['lastName'],
                            email: data['email'],
                            activation_token: data['activationToken'],

                          };
                         //console.log('user');
                        // console.log(user);
                         this.http.sendEmail(`${environment.mailerUrl}/activateaccount`, user).subscribe(
                            data => {
                              let res:any = data;
                              console.log(
                                `${user.email} is successfully registered and mail has been sent `
                              );
                            },
                            err => {
                              console.log(err);
                              this.loading = false;
                            },() => {
                              this.loading = false;
                            }

                          );

                           // this.cookieService.deleteAll();
                         this.cookieService.deleteAll();


                         this.router.navigate(['/activation'], { relativeTo: this.route });

                       },
                       error => {

                           this.alertService.error(error);
                         this.router.navigate(['/register-step1'], { relativeTo: this.route });
                           this.loading = false;
                       });

     }

    }
}
