import { Injectable } from '@angular/core';
import { User } from '@app/_models/user';
import { PasswordDetails } from '@app/_models/password-details';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  personalDetails: User;
  passwordDetails: PasswordDetails;

  constructor() {

    this.personalDetails = new User();
    this.passwordDetails = new PasswordDetails();

   }

  //  postData(){
  //   let newCombinedObject = {
  //     passwordDetails : this.passwordDetails,
  //     email : this.personalDetails.email,
  //     confirm_email : this.personalDetails.confirm_email

  //   }

  // }
}
