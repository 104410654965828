import { Injectable ,} from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from '@app/_services';
import {first} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class ConditionalGuard implements CanActivate {
  private user:any;


  constructor(       private router: Router,
                     private userService: UserService) {
    this.user = this.userService.userValue;

    console.log('canActivate');
    console.log( this.user);


  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {
   // const user = this.userService.userValue;
    console.log('canActivate');

    console.log( this.user);
    if ( this.user.clientKYCStatus.name.toUpperCase() == 'APPROVED' || this.user.clientKYCStatus.name.toUpperCase() =='WEBTRADER') {
      return true;
    }
    if ( this.user == null) {
      this.router.navigate(['/user/login'], {queryParams: {returnUrl: _state.url}});
    }else{

      if( this.user.clientType=='C') {


          if ( this.user.clientStatus.name.toUpperCase() == 'WAITING-APPROVAL' ) {

            this.router.navigate(['/user/documentsBusiness-list']);
          }else{
            this.router.navigate(['/user/business-profile']);
          }


      }else{
        if ( this.user.clientStatus.name.toUpperCase() == 'WAITING-APPROVAL' ) {

          this.router.navigate(['/user/documents-list']);
        }else{
          this.router.navigate(['/user/profile']);
        }


      }
    }
    return false;
  }
}
