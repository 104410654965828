import { Component, OnInit } from '@angular/core';
import {UserService} from "@app/_services";
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component

@Component({
  selector: 'app-approved-messages',
  templateUrl: './approved-messages.component.html',
  styleUrls: ['./approved-messages.component.css']
})
export class ApprovedMessagesComponent implements OnInit {

  constructor( private userService: UserService,private bnIdle: BnNgIdleService ) { }

  ngOnInit(): void {
   // this.userService.logout();
    //60 = 1 minute
    this.bnIdle.startWatching(5).subscribe((res) => {
      if (res) {
        console.log('session expired');
        this.userService.logout();
      }
    });

  }

}


