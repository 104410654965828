import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '@app/_services';
import {User} from '@app/_models';
import {first} from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SideMenuComponent implements OnInit {
  user: any;
  userwallet: any;
  url: string | ArrayBuffer = '';
  myForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    file: new UntypedFormControl('', [Validators.required]),
    fileSource: new UntypedFormControl('', [Validators.required])
  });
  @ViewChild("master_balance") spanView: ElementRef;


  constructor(private userService: UserService) {
    this.user = this.userService.userValue;
    this.userwallet = this.userService.userwalletValue;
  }

  get f(){
    return this.myForm.controls;
  }
  /*onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        const formData = new FormData();
        formData.append('file', this.myForm.get('fileSource').value);

       /* this.http.post('http://localhost:8001/upload.php', formData)
          .subscribe(res => {
            console.log(res);
            alert('Uploaded Successfully.');
          })*/
     /* };
    }
  }*/

  logout() {
    this.userService.logout();
  }
  updateBalanceElem(bal, cur){

    /// this.spanView.nativeElement.innerHTML = bal.toFixed(2) +' '+ cur;

  }
  ngOnInit() {

    //this.user= JSON.parse( this.user);
   // this.userwallet = JSON.parse( this.userwallet);
    console.log('userwallet');
    console.log(this.user);
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();

    (function(){

      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/655498cdcec6a9128210021b/1hg0q8qtg';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
    })();


  }

}

