import {Component, ElementRef, OnInit,Inject, ViewChild,AfterViewInit} from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ModalService,UserService,LogService,UploadService } from './_services';
import {Subscription, timer} from 'rxjs';

import { DOCUMENT } from '@angular/common';


import { User } from './_models';
import {CookieService} from "ngx-cookie-service";
import {SideMenuComponent} from "@app/sidemenu/sidemenu.component";
import {map} from "rxjs/operators";

@Component({ selector: 'app-root', templateUrl: 'app.component.html' ,styleUrls: ['app.component.scss']})

export class AppComponent implements AfterViewInit{
  deviceInfo = null;
  isDesktopDevice: boolean;
  isTablet: boolean;
  isMobile: boolean;
  url :any;//'';
    user: any;
    unread_notifications:number;
  timerSubscription: Subscription;
  element: HTMLElement;
  isPopupVisible: boolean = false;

  openPopup(): void {
    this.isPopupVisible = true;
  }

  onPopupVisibilityChange(isVisible: boolean): void {
    this.isPopupVisible = isVisible;
  }

  bodyText = 'This text can be updated in modal 1';
  // @ViewChild('unread_notifications') span: ElementRef;
  @ViewChild('unread_notificationsspan', { static: false }) public unread_notificationsspan: ElementRef;
      @ViewChild(SideMenuComponent) sub: SideMenuComponent;
  @ViewChild('main_currency', { static: false }) public main_currency: ElementRef;

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
console.log("avataer");
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        console.log("this.url");
        this.url = event.target.result;
        console.log(this.url);
        var   postdata = {};
        postdata['ImageFile'] = <File>this.url;
        postdata['clientId'] = this.user.id;
      //  postdata['filename']="avatar_"+this.user.id.toString();
        const formData = new FormData();
       /* formData.append('ImageFile',  this.url);
        formData.append('clientId',  this.user.id);
        formData.append('filename',  "avatar_"+this.user.id.toString());
        console.log("formData");
        console.log(formData);*/
       // formData.append('clientId',  this.user.id);
        formData.append('file',  this.url);
        console.log("formData");
        console.log(formData);
        console.log(this.userService.uploadAvatar(this.user.id,formData));
        /*.subscribe(x => {
          console.log(x);


        });*/
        /* this.http.post('http://localhost:8001/upload.php', formData)
           .subscribe(res => {
             console.log(res);
             alert('Uploaded Successfully.');
           })*/
    }
    }
  }
  loadCountNotifications(user){
    this.userService.GetCountNotificationsByClientd(user)
      .subscribe(x => {
     //  alert(x.result);
        this.unread_notifications=x.result;
        // this.sub.updateBalanceElem(x.balance, x.currencyId);
        if(this.unread_notifications>0){

          document.getElementById("unread_notificationsspan" ).innerHTML=this.unread_notifications.toString();

        }else{
          document.getElementById("unread_notificationsspan" ).innerHTML='';

        }

      });

  }
  ngAfterViewInit() {
    console.log("user");
    console.log(this.user);
    if (!this.user) {
      return;
    }
    this.main_currency.nativeElement.innerText=this.user.mainCurrency;

    this.url = "https://crm.assetise.io/api/Resources/Avatars/avatar_" + this.user.id.toString() + ".png";
    this.loadCountNotifications(this.user.id);
    if(!this.user.enabled2fa){
      this.openPopup();

    }
    this.timerSubscription = timer(0, 60000).pipe(
      map(() => {
    //    alert("here");
      this.loadCountNotifications(this.user.id);// load data contains the http request

      })
    ).subscribe();


  }

    constructor(private userService: UserService,
                @Inject(DOCUMENT) document: Document,
                private route: ActivatedRoute,
                private logger: LogService,
                private cookieService: CookieService,
                protected modalService: ModalService,
                private router: Router,
  private deviceService: DeviceDetectorService) {
        this.user = this.userService.userValue;
        this.userService.user.subscribe(x => this.user = x);
      this.epicFunction();
    }

  ngOnInit() {

    this.user= JSON.parse( this.user);
  // this.url=this.user.avatar.filepath;

     console.log('ngOnInit');
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();

    (function(){

      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/655498cdcec6a9128210021b/1hg0q8qtg';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
    })();

    this.logger.log('Login user  =' + JSON.stringify(this.user));
  }



  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log('deviceInfo');
    console.log( this.deviceInfo);
  }

    logout() {
        this.userService.logout();
    }




}
