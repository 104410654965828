import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Transaction } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class TransactionService {
  private transactionSubject: BehaviorSubject<Transaction>;
  public transaction: Observable<Transaction>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.transactionSubject = new BehaviorSubject<Transaction>(JSON.parse(localStorage.getItem('transaction')));
    this.transaction = this.transactionSubject.asObservable();
  }

  public get transactionValue(): Transaction {
    return this.transactionSubject.value;
  }



  create(transaction: Transaction) {
    return this.http.post(`${environment.apiUrl}/transactions/create`, transaction);
  }


  deposit(params) {
    return this.http.post(`${environment.apiUrl}/transactions/deposit`, params);
  }

  withdraw(params) {
    return this.http.post(`${environment.apiUrl}/transactions/withdraw`, params);
  }

  getTransferById(id: string) {
    return this.http.get<any[]>(`${environment.apiUrl}/transfers/GetTransferDetailsById/${id}`);
  }

  AccountTransactions(id: string,params={}){
    if(params["entity"]=="options"){
      return this.http.post<any[]>(`${environment.apiUrl}/transactions/OptionsAccountTransactions/${id}`, params);

    }
    if(params["entity"]=="cfds") {
      return this.http.post<any[]>(`${environment.apiUrl}/transactions/FxAccountTransactions/${id}`, params);
//      return this.http.post<any[]>(`${environment.apiUrl}/transactions/GetAllEntitiesTransactionsByClientId/${id}`,params);
    }
  }

  verifyTransfer( params) {
    return this.http.post(`${environment.apiUrl}/transfers/verify`, params);

  }

  getAllTransfers(id: string,params={}) {

      return this.http.get<any[]>(`${environment.apiUrl}/transfers/getTransfersByClientId/${id}`);
  }

  getAll(id: string,params={}) {
    if(params){
      return this.http.get<any[]>(`${environment.apiUrl}/transactions/GetTransactionsByClientId/${id}`);

    }else{
      return this.http.get<any[]>(`${environment.apiUrl}/transactions/GetTransactionsByClientId/${id}`);

    }
  }

  getAllEntities(id: string,params={}) {

      return this.http.post<any[]>(`${environment.apiUrl}/transactions/GetAllEntitiesTransactionsByClientId/${id}`,params);


  }

  getById(id: string) {
    return this.http.get<any[]>(`${environment.apiUrl}/transactions/GetTransactionDetailsById/${id}`);
  }

  GetTransactionByPSPReference(ref: string) {
    return this.http.get<any[]>(`${environment.apiUrl}/transactions/GetTransactionByPSPReference/${ref}`);
  }

  getTransactioMongdb(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/clientnotes/GetWithdrawalMongo/${id}`);
  }




}
