import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Account,AccountType } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class PartnerService {

  private userwalletSubject: BehaviorSubject<any>;
  public userwallet: Observable<any>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {

    this.userwalletSubject = new BehaviorSubject<any>(JSON.parse(JSON.stringify(localStorage.getItem(('userwallet')))));
    this.userwallet = this.userwalletSubject.asObservable();
  }
  becomePartner(params) {



    const headers = { 'content-type': 'application/json'}
    //const body=params;
    const body=JSON.parse(JSON.stringify(params));

    return this.http.post<any[]>(`${environment.partnerUrl}/registerAssetiseAff`, body,{'headers':headers})

  }
}
