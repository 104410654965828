
<div class="row no-gutters">
    <div class="col-sm-12 col-lg-9 col-xl-9 offset-xl-1 offset-lg-1 pl-5">  
      <p class="text-xl-right" style="position:relative; top:60px;"><span style="color:#ACB9B2;">Residential country/region:</span> <span class="country-bar"><img src="/assets/img/cyprus-flag-icon-32.png" class="flag mr-2" /> Cyprus (Κύπρος)</span></p>
      <h1 class="dashboard-heading mt-4 mb-5 text-white">KYC Documents </h1>          
    </div>

    <div class="col-sm-12 col-lg-9 col-xl-9 offset-xl-1 offset-lg-1 pl-5 mt-5">  
        <div class="row no-gutters">
            
            <div class="col-xl pl-0">     
                <div class="row no-gutters">
                <div class="col-sm-12 bg-box">
                    <div class="row mt-4 mb-3 pl-3 pr-3">
                    <div class="col-sm-12 mb-3  pl-5 pr-0">
                        <h3 class="text-white font-weight-bold d-inline" style="line-height:60px;">Rules</h3>                        
                    </div>
    
                    <div class="col-sm-12 mt-0 pl-5 pr-5 mb-5">                             
                        <ul class="verified mt-0 mb-4">
                          <li><div class="float-left" style="width:40px; height:20px; clear: both;"><img src="/assets/img/icon-completed.svg" class="icon-list-verified"/></div>Document is not expired</li>
                          <li><div class="float-left" style="width:40px; height:20px; clear: both;"><img src="/assets/img/icon-completed.svg" class="icon-list-verified"/></div>Not under 18 years old</li>
                          <li><div class="float-left" style="width:40px; height:40px; clear: both; padding:9px 0px;"><img src="/assets/img/icon-rejected.svg" class="icon-list-verified"/></div>Warning: Name or surname does not match with the document data</li>
                        </ul>    
                    </div>
                    
                    
    
                    </div>
                    
                </div>
                </div>  
            </div>


            
        <div class="col-xl pl-5">     

            <div class="row no-gutters">
            <div class="col-sm-12">
                <div class="row mt-4 mb-3 pl-3 pr-3">

                <div class="col-sm-12 mt-4 pl-5 pr-5 text-center"> 

                    <img src="assets/img/icon-rejected.svg" class="text-center mb-3" />
                    <h4 class="text-white font-weight-bold mt-3 mb-4">Your KYC request status was rejected.</h4>

                    <div class="alert alert-danger offset-1 col-10" role="alert" style="color:#E54154;"><img src="/assets/img/icon-error.svg" class="mr-2" /> Rejected reason: Invalid proof of address</div>

                    <button class="btn btn-register mt-3 text-left"><span  class="float-left text-uppercase"></span>TRY AGAIN <img src="../../assets/img/arrow.png" class="arrow-login text-right" alt="" width="8" height="12"></button>


                </div>
                </div>
                
            </div>
            </div>  
        </div>


        </div>
    
    </div>

    <div class="col-sm-12 col-lg-9 col-xl-9 offset-xl-1 offset-lg-1 pl-5 mt-5">  
        <div class="row no-gutters mt-5"> 
            <div class="col-sm-8 offset-sm-2 borderRadius" style="background-color: #1E443B;">
                <div class="row"> 
                    <div class="col-sm-7 coin-name-color pt-1 pb-1"><div class="float-left mr-2" style="width:40px; height:40px; clear: both; padding:9px 10px;"><img src="/assets/img/icon-exclamation-mark.svg" class="icon-list-verified"/></div>If you still cannot complete the verification process due to errors please contact support. </div>
                    <div class="col-sm-5 text-center"><button class="btn btn-register mt-2 text-left mb-2"><span  class="float-left text-uppercase"></span>CONTACT SUPPORT <img src="../../assets/img/arrow.png" class="arrow-login text-right" alt="" width="8" height="12"></button></div>
                </div>
                
            </div>
        </div>
    
    </div>

    
</div>