export class Transaction {
  id: number;
  type: string;
  amount: number;
  amount_modified: number;
  rate_id: number;
  currency: string;
  payment_method: string;
  status: string;
  is_transfer: string;
  date_created: string;
  date_updated: string;
  token: string;
}
