export class Document {
  id: number;
  clientId: number;
  name: string;
  documentType: DocumentType;
  documentStatus: DocumentStatus;
  key?: string;
  path: string;
  expiration: Date;
  uploadDate? : Date;
  downloadURL? : string;
}

export class DocumentType {
  id: number;
  name: string;
}

export class DocumentStatus {
  id: number;
  name: string;
}
