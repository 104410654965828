<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap" rel="stylesheet">

<header class="landing align-items-center lato">
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8 col-12 landing-text">
                <img class="logo" src="assets/img/logo.svg" width="300">
                <h3 class="white mt-5">Something exciting is coming.</h3>
                <h6 class="white mt-5">Get notified when we launch.</h6>
                <form>
                    <div class="row features">
                        <div class="input-group my-3">
                            <input type="text" class="form-control footer" placeholder="Enter your email address">
                            <button class="btn btn-submit" type="button"> <img src="assets/img/arrow.png" alt="" class="arrow-email" width="10" height="15"> </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-4 col-md-4 col-4 text-center">

            </div>
        </div>
        <div class="row justify-content-center text-center">
            <div class="text-center">
                <p class="copy">Copyright 2022 Assetise.io All rights reserved.</p>
            </div>
        </div>
    </div>

   

</header>
